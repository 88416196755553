<template>
  <div class="min-h-[70vh]">
    <table class="w-full text-center main-table">
      <thead class="bg-grayScale100">
        <tr>
          <th
            class="p-2 border border-solidborder-grayScale300 border-t-0 text-xs text-grayScale900 cursor-pointer capitalize"
            v-for="(title, i) in theadTitles"
            :key="i"
            :class="{
              'text-left border-r-0': i === 0,
              'cursor-default': i === 7 || i === 5,
            }"
            @click="sortTable(i, title)"
          >
            <div
              class="flex gap-2 items-center justify-center"
              :class="{
                '!justify-start': i === 0,
              }"
            >
              {{ i18n.te(title) && title?.length ? $t(title) : title }}
              <div class="flex flex-col" v-if="isSort && i !== 5 && i != 7">
                <el-icon
                  :class="{
                    'text-orangeThin':
                      title === sortedTitle && sortedIndex == 1,
                  }"
                  ><CaretTop
                /></el-icon>
                <el-icon
                  :class="{
                    'text-orangeThin':
                      title === sortedTitle && sortedIndex == 2,
                  }"
                  ><CaretBottom
                /></el-icon>
              </div>
            </div>
          </th>
          <th>
            <slot name="button" />
          </th>
        </tr>
      </thead>
      <tbody>
        <slot></slot>
      </tbody>
    </table>
  </div>
</template>
<script setup>
import { CaretBottom, CaretTop } from "@element-plus/icons-vue";
import { ref } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps(["theadTitles", "isSort"]);
const emits = defineEmits(["sort"]);
const sortedTitle = ref("");
const sortedIndex = ref(null);
const i18n = useI18n();

const sortTable = (i, title) => {
  if (title != sortedTitle.value) {
    sortedIndex.value = null;
  }
  sortedTitle.value = title;

  if (sortedIndex.value === 1) sortedIndex.value = 2;
  else if (sortedIndex.value === 2) sortedIndex.value = 1;
  else sortedIndex.value = 1;

  emits("sort", title);
};
</script>

<style lang="scss">
.main-table {
  thead {
    th {
      &:first-child {
        position: relative;
        text-align: left;
        position: relative;
        padding-left: 20px;
        max-width: 400px;
        &::after {
          content: "";
          position: absolute;
          top: 0;
          right: 0px;
          width: 10px;
          height: 100%;
          box-shadow: 6px 0 4px rgba(0, 0, 0, 0.11);
        }
      }
      &:nth-child(2) {
        border-left: none;
      }
    }
  }
  tbody {
    tr {
      border-bottom: 1px solid #eee;
      td {
        padding: 8px;
        font-size: 14px;
        font-weight: 800;
        color: var(--grayScale800);
        &:first-child {
          text-align: left;
          position: relative;
          padding-left: 20px;
          width: 400px;
          white-space: nowrap;
          &::after {
            content: "";
            position: absolute;
            top: 0;
            right: 0px;
            width: 10px;
            height: 100%;
            box-shadow: 6px 0 4px rgba(0, 0, 0, 0.11);
          }
        }
      }
    }
  }
  .el-icon {
    width: 10px !important;
    height: 6px !important;
    line-height: 10px !important;
  }
}
</style>
