<template>
  <div class="homework">
    <div class="demo-collapse">
      <el-collapse>
        <el-collapse-item title="Homework" name="1">
          <div class="collapse-content">
            <div class="collapse-item">
              <div
                v-for="(detail, i) in details"
                :key="i"
                class="homework-body"
              >
                <h2>{{ detail.title }}</h2>
                <h4>{{ $t("Projects.description") }}</h4>
                <div class="description">
                  <p
                    v-html="
                      markdownForHW(
                        course?.project?.description[detail.lang] || '-'
                      )
                    "
                  ></p>
                </div>
                <h4>{{ $t("Projects.requirements") }}</h4>
                <ul class="requirements-list">
                  <li v-for="(req, j) in course.project?.requirements" :key="j">
                    {{ j + 1 }}.{{ req[detail.lang] }} - {{ req.coins }}
                    <span> coin </span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="copy-btn">
              <button @click="copyText">{{ $t("Projects.copy") }}</button>
            </div>

            <div v-if="course.project?.images?.length">
              <h4>Homework Images</h4>
              <div class="images">
                <div v-for="(image, k) in course.project.images" :key="k">
                  <el-image
                    style="width: 250px; height: 150px; object-fit: contain"
                    :src="medialink + image"
                    :zoom-rate="1.2"
                    :preview-src-list="allImages()"
                    :initial-index="k"
                    fit="cover"
                  />
                </div>
              </div>
            </div>
            <div v-if="course.project?.files?.length">
              <div v-for="(file, k) in course.project.files" :key="k">
                <a
                  target="_blank"
                  :href="medialink + file"
                  download
                  style="
                    margin-right: 10px;
                    display: flex;
                    align-items: center;
                    gap: 5px;
                  "
                >
                  <img
                    src="@/assets/img/homework-file-icon.png"
                    alt="file"
                    width="20"
                  />
                  {{ file.slice(file.lastIndexOf("/") + 1) }}
                </a>
              </div>
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>

<script>
import { medialink } from "@/apiConfig/medialink";
import { marked } from "marked";
import { ElMessage } from "element-plus";
export default {
  props: ["course"],

  setup(props) {
    marked.setOptions({
      headerIds: false,
      mangle: false,
    });
    const details = [
      {
        title: "Uyga Vazifa",
        lang: "uz",
      },
      {
        title: "Домашнее задание",
        lang: "ru",
      },
    ];
    const allImages = () => {
      let images = [];
      props.course.project.images.forEach((image) => {
        images.push(medialink + image);
      });
      return images;
    };

    const markdownForHW = (text) => {
      return marked(text);
    };
    const copyText = () => {
      let text = "";
      details.forEach((detail) => {
        text += detail.title + "💻" + "\r\n";
        if (detail.lang === "uz") {
          text += "=> " + props.course.project.description.uz + "\r\n";
        } else {
          text += "=> " + props.course.project.description.ru + "\r\n";
        }
        text += "Coins:" + "\r\n";
        props.course?.project?.requirements.forEach((req, i) => {
          text +=
            i +
            1 +
            "." +
            req[detail.lang] +
            "-" +
            req.coins +
            "coins" +
            "🪙" +
            "\r\n";
        });
        text +=
          "--------------------------------------------------------------------" +
          "\r\n";
      });

      navigator.clipboard.writeText(text);
      ElMessage({
        showClose: true,
        message: "Homework details copied!",
      });
    };
    return {
      details,
      medialink,
      allImages,
      markdownForHW,
      copyText,
    };
  },
};
</script>
