<template>
  <div class="homework">
    <div class="demo-collapse">
      <el-collapse>
        <el-collapse-item title="Methodology" name="1">
          <div class="collapse-content">
            <div class="tabBtns">
              <button
                :class="{ active: tabVal === 'UZ' }"
                @click="tabVal = 'UZ'"
              >
                UZ
              </button>
              <button
                :class="{ active: tabVal === 'RU' }"
                @click="tabVal = 'RU'"
              >
                RU
              </button>
            </div>
            <div v-if="tabVal === 'UZ'">
              <h1 class="text-2xl">
                {{ $t("Projects.topic") }} {{ title.uz || "No data" }}
              </h1>
              <div
                v-for="(item, i) in methodology"
                :key="i"
                class="homework-body"
              >
                <h2>{{ item?.title_uz || "No data" }}</h2>
                <div class="flex !items-center">
                  <div class="description w-[85%]">
                    <div
                      class="min-w-[300px] description-item"
                      v-html="item?.description_uz || 'No data'"
                    ></div>
                  </div>
                  <div class="description">
                    <p>
                      {{
                        DateTime.fromISO(item?.from_minute).toFormat("HH:mm")
                      }}
                      -
                      {{ DateTime.fromISO(item?.to_minute).toFormat("HH:mm") }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <h1 class="text-2xl">
                {{ $t("Projects.subject") }} {{ title.ru || "No data" }}
              </h1>
              <div
                v-for="(item, i) in methodology"
                :key="i"
                class="homework-body"
              >
                <h2>{{ item?.title_ru || "No data" }}</h2>
                <div class="flex !items-center">
                  <div class="description w-[85%]">
                    <div v-html="item?.description_ru || 'No data'"></div>
                  </div>
                  <div class="description">
                    <p>
                      {{
                        DateTime.fromISO(item?.from_minute).toFormat("HH:mm")
                      }}
                      -
                      {{ DateTime.fromISO(item?.to_minute).toFormat("HH:mm") }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div></el-collapse-item
        ></el-collapse
      >
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { DateTime } from "luxon";

const props = defineProps(["methodology", "title"]);

const tabVal = ref("UZ");
</script>
<style lang="scss">
.tabBtns {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
  button {
    background-color: #f5f5f5;
    color: #bdbdbd;
    border-radius: 3px;
    border: 0.6px solid #eeeeee;
    padding: 4px 18px;
    font-family: Avenir;
    font-size: 14px;
    font-weight: 800;
    line-height: 19px;
  }
  .active {
    background-color: #ff805d;
    color: #fff;
  }
}
.homework {
  .description-item {
    a {
      color: blue;
      text-decoration: underline;
    }
  }
}
</style>
